import CurrencyFormatter from '@/commons/modules/currency/CurrencyFormatter';
import currencyConfig from './currencyConfig.json';

export type CurrencyFormatterConfig = [
  number, // Decimal count
  string, // Decimal separator
  string, // Thousand separator
  string, // Currency symbol
  boolean, // Currency before value
];

export type CurrencyLanguageConfig = {
  [key: string]: CurrencyFormatterConfig,
  default: CurrencyFormatterConfig,
}

export type TenantCurrencyConfig = {
  [key: string]: Partial<CurrencyLanguageConfig>,
  default: CurrencyLanguageConfig,
}

export interface CurrencyConfig {
  [key: string]: Partial<TenantCurrencyConfig>,
  default: TenantCurrencyConfig,
}

export interface FormatterOptions {
  omitDecimals?: boolean,
  omitDecimalSeparator?: boolean,
  omitThousandsSeparator?: boolean,
  omitCurrencySymbol?: boolean,
  useCurrencyCode?: boolean,
}

// Temp config lookup until a better solution is implemented via API or dynamic import. TBD.
function getFormatterConfig(tenant: string, currency: string, language: string) {
  const config = currencyConfig as unknown as CurrencyConfig;

  const configByTenant = config[tenant] || config.default;

  const configByCurrency = configByTenant[currency] // Tenant > currency
    || configByTenant.default // Tenant > default currency
    || config.default[currency] // Default tenant > currency
    || config.default.default; // Default tenant > default currency

  return configByCurrency[language] // Currency > lang
    || configByCurrency.default // Currency > default lang
    || config.default[currency]?.default // Default tenant > currency > default lang
    || config.default.default.default; // Default tenant > default currency > default lang
}

class Config {
  tenant!: string;
  language!: string;

  constructor(tenant: string, language: string) {
    this.tenant = tenant
    this.language = language
  }
}

export default class Currency {
  config!: Config;
  decimals!: number;
  decimalsSeparator!: string;
  thousandsSeparator!: string;
  currencySymbol!: string;
  currencyBeforeValue!: boolean;
  tenant!: string;
  currency!: string;
  language!: string;

  constructor() {
    this.setConfig('', '', '');
  }

  public resolveCurrency(currencyCode: string) {
    const currency = new Currency();
    currency.setConfig(this.config.tenant, currencyCode, this.config.language);
    return currency;
  }

  public setConfig(tenant: string, currency: string, language: string) {
    const formatterConfig = getFormatterConfig(tenant, currency, language);

    const [
      decimals,
      decimalsSeparator,
      thousandsSeparator,
      currencySymbol,
      currencyBeforeValue,
    ] = formatterConfig;

    this.config = new Config(tenant, language);
    this.decimals = decimals;
    this.decimalsSeparator = decimalsSeparator;
    this.thousandsSeparator = thousandsSeparator;
    this.currencySymbol = currencySymbol;
    this.currencyBeforeValue = currencyBeforeValue;
    this.tenant = tenant;
    this.currency = currency;
    this.language = language;
  }

  public format(amount: number, options: FormatterOptions = {}) {
    const currencySymbol = options.useCurrencyCode
      ? this.currency : this.currencySymbol;

    const currencyFormatter = new CurrencyFormatter(amount)
      .withDecimals(options.omitDecimals ? 0 : this.decimals)
      .withDecimalSeparator(options.omitDecimalSeparator ? '' : this.decimalsSeparator)
      .withThousandsSeparator(options.omitThousandsSeparator ? '' : this.thousandsSeparator)
      .withCurrencySymbol(options.omitCurrencySymbol ? '' : currencySymbol)
      .withCurrencyBeforeValue(this.currencyBeforeValue);

    return currencyFormatter.format();
  }

  public breakdown(
    total: number,
    taxPercentage: number = 21,
    formatterOptions?: FormatterOptions,
  ): { basePrice: string; taxes: string } {
    const basePrice = total * (100 - taxPercentage) / 100;
    const basePriceRounded = Math.round(basePrice * 100) / 100;
    const taxes = total - basePriceRounded;

    return {
      basePrice: this.format(basePriceRounded, formatterOptions),
      taxes: this.format(taxes, formatterOptions),
    };
  }


  public getCurrencySymbol(currencyName: string) {
    const symbol = (<Record<string, any>>currencyConfig.default)?.[currencyName].default[3];

    return symbol;
  }

}
