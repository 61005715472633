import PlansService from '@/commons/services/PlansService';
import { CatalogFilter, FilterQuery, StationMap, TagType, QuotaBase, QuotaProps, Quota, SelectedPlan } from '@/commons/models';
import StringFilter from '@/commons/modules/catalog-filters/StringFilter';
import {
  PlansByFamily,
  CatalogPlan,
  CatalogPlanPrice,
  MappedPlan,
  PhasePrice,
} from '@/commons/models';

type MapperFunc = (value: MappedPlan, index: number, array: MappedPlan[]) => MappedPlan;

export default class Catalog {
  plans: Array<CatalogPlan>;
  currency: string;
  filteredPlans: Array<MappedPlan>;
  mappedPlans: Array<MappedPlan>;
  catalogFilters: Array<CatalogFilter> = [];
  stations: Array<StationMap>;

  private constructor(
    plans: PlansByFamily,
    mappedPlans: Array<MappedPlan>,
    currency: string,
    stations: Array<StationMap>
  ) {
    this.plans = Object.values(plans).flat() as CatalogPlan[];
    this.currency = currency;
    this.mappedPlans = mappedPlans;
    this.filteredPlans = [...this.mappedPlans];
    this.stations = stations;
  }

  /**
   * @param usePlansPage Support for deprecated logic where we fetch the whole
   * plans page instead of just the plans.
   */
  public static async build(usePlansPage = false): Promise<Catalog> {
    const { data } = usePlansPage
      ? await PlansService.fetchData()
      : await PlansService.fetchPlans();
    const { plans, mappedPlans, activeCurrency, stations } = data.content;
    return new Catalog(plans, mappedPlans, activeCurrency, stations);
  }

  public withFilters(catalogFilters: Array<CatalogFilter>): Catalog {
    this.catalogFilters = catalogFilters;
    return this;
  }

  public sortByPrice() {
    this.mappedPlans.forEach((plan) =>
      plan.planItems.sort((a, b) => Number(a.price) - Number(b.price))
    );
  }

  public withMapper(mapper: MapperFunc): Catalog {
    const mappedPlans = this.mappedPlans.map(mapper);
    this.mappedPlans = mappedPlans;
    this.filteredPlans = [...mappedPlans];
    return this;
  }

  public static hasQuery(query: FilterQuery): boolean {
    return Boolean(
      query &&
        Object.keys(query).length > 0 &&
        Object.values(query).some(Boolean) &&
        query.constructor === Object
    );
  }

  private getCatalogFilter(name: string) {
    return this.catalogFilters.find((filter) => {
      return filter.name === name;
    });
  }

  public filter(query: FilterQuery): void {
    if (!Catalog.hasQuery(query)) {
      this.filteredPlans = [...this.mappedPlans];
      return;
    }

    let plans = [...this.mappedPlans];

    Object.keys(query).forEach((key) => {
      if (!query[key] || !this.getCatalogFilter(key)) {
        return;
      }

      const catalogFilter = this.getCatalogFilter(key);
      plans = catalogFilter?.filter(query[key], plans, query) || [];
    });

    this.filteredPlans = plans;
  }

  public getPlanById(planId: string): SelectedPlan | {} {
    if (!planId) return {};

    const plans = this.mappedPlans.flatMap((mappedPlan) =>
      mappedPlan.planItems.map((plan) => {
        return {
          ...plan,
          family: mappedPlan.planId,
        };
      })
    );

    const byId = new StringFilter('id');
    const filteredPlans = byId.filter(planId, plans);

    return filteredPlans[0];
  }

  public getRecurrencyByPlanId(plans: CatalogPlan[], planId: string) {
    const planSelected = plans.find((plan) => plan.name === planId);

    return planSelected?.recurrency;
  }

  public getQuotaByPlanTag(plans: CatalogPlan[], { currency, ...props }: QuotaProps) {
    const plansByTag = this.filterPlansBy(plans, props);
    const quota = this.retrieveQuotaByTag(plansByTag, props.tag.name, currency);

    return quota;
  }

  protected filterPlansBy(plans: CatalogPlan[], { tag, family }: QuotaBase) {
    const plansByFamily = plans.filter((plan) => {
      const hasFamily = plan.planFamily === family;
      const hasTagValue = plan.tags.some((planTag) => planTag === `${tag.default}_${tag.defaultValue}`);

      return hasFamily && hasTagValue && plan.visible;
    });

    return plansByFamily;
  }

  protected retrieveQuotaByTag(plansByTag: CatalogPlan[], tagName: TagType, currency: string): Quota[] {
    const planPrice = Number(plansByTag[0].price.EVERGREEN[currency].qty);

    const quota = plansByTag.map(({ tags, i18n, name, recurrency }) => {
      const quotaTag = tags.find((tag) => tag.startsWith(tagName)) ?? '';
      const quota = Number(quotaTag.split(/(\d+)/)[1]);
      const price = <number>(this.getPlansPriceById().find(({ id }) => id === name)?.price ?? 0);
      const priceDifference = price - planPrice;

      return { i18n, quota, name, recurrency, price, priceDifference };
    });

    return quota;
  }

  protected getPlansPriceById() {
    return this.mappedPlans.flatMap((mappedPlan) => mappedPlan.planItems);
  }

  protected getCheapestFare(fare1: PhasePrice, fare2: PhasePrice) {
    return fare1[this.currency] < fare2[this.currency] ? fare1 : fare2;
  }

  public getLowestPlanPrice() {
    const priceList: Array<CatalogPlanPrice> = this.plans.map(
      (plan: CatalogPlan) => plan.price
    );
    const lowestFares = priceList.map((price: CatalogPlanPrice) => {
      const fareList = Object.values(price);
      const cheapestFare = fareList.reduce(
        this.getCheapestFare.bind(this),
        fareList[0]
      );
      return cheapestFare[this.currency].qty;
    });
    return Math.min.apply(Math, lowestFares);
  }
}